import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalFieldComponent } from '@app/forms/modal-field.component';
import { AppState } from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { CustomFieldsAppliesTo, CustomFieldsModel } from '@reducers/orm/custom-fields/custom-fields.model';
import { getCustomFieldsByAppliesTo } from '@reducers/orm/custom-fields/custom-fields.selectors';
import { CustomFieldsService } from '@reducers/orm/custom-fields/custom-fields.service';
import { RequiredShiftModel } from '@reducers/orm/required-shift/required-shift.model';
import { ScheduleModel } from '@reducers/orm/schedule/schedule.model';
import { ShiftModel } from '@reducers/orm/shift/shift.model';
import { TimesheetModel } from '@reducers/orm/timesheet/timesheet.model';
import { Subscription } from 'rxjs';

import { CustomFieldsTypesComponent } from '../custom-fields/custom-fields-types.component';

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the CustomFieldListNewComponent instead when doing modal revamps.
 *
 * TODO: Remove when no longer used
 */
@Component({
  selector: 'custom-field-list',
  templateUrl: './custom-field-list.component.html',
  standalone: true,
  imports: [CommonModule, ModalFieldComponent, CustomFieldsTypesComponent],
})
export class CustomFieldListComponent implements OnInit, OnChanges {
  public mappedCustomFields: any[] = [];

  private dataSubs = new Subscription();
  private customFields: CustomFieldsModel[] = [];

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public model: TimesheetModel | ShiftModel | ScheduleModel | RequiredShiftModel;

  @Input()
  public appliance: CustomFieldsAppliesTo;

  @Input()
  public lineBreak = true;

  @Input()
  public enabled = true;

  public constructor(
    private store: Store<AppState>,
    private customFieldsService: CustomFieldsService,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(
      this.store.pipe(select(getCustomFieldsByAppliesTo(this.appliance))).subscribe((customFields) => {
        this.customFields = customFields;
        this.createCustomFieldForm();
      }),
    );
  }

  public ngOnChanges(): void {
    this.createCustomFieldForm();
  }

  private createCustomFieldForm() {
    if (!this.customFields || this.customFields.length === 0) {
      return;
    }

    if (this.form.contains('customField')) {
      this.form.removeControl('customField');
    }

    const mapping = this.customFieldsService.createCustomFieldMapping(
      this.customFields,
      this.model?.custom_fields ?? {},
    );
    this.mappedCustomFields = mapping.mappedCustomFields;
    this.form.addControl('customField', mapping.formArray);

    if (this.enabled) {
      this.form.get('customField').enable();
    } else {
      this.form.get('customField').disable();
    }
  }
}
