<ng-container *ngFor="let customFieldGroup of mappedCustomFields; first as isFirst; last as isLast">
  <ng-container *ngIf="isFirst && lineBreak">
    <hr />
  </ng-container>
  <modal-field
    [ngClass]="{ 'last-row': isLast }"
    [control]="customFieldGroup.form.get('value')"
    [label]="customFieldGroup.name"
    [tooltipText]="customFieldGroup.description"
  >
    <app-custom-fields-types class="w-full" [customFieldGroup]="customFieldGroup"></app-custom-fields-types>
  </modal-field>
</ng-container>
